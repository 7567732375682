import React from "react";
import "./TopNavbar.scss";

export class TopNavbar extends React.Component<any, any> {
    render() {
        return (
            <div className="top-navbar">
                <a href="https://fontannyplywajace.pl/" className="logo-link">
                    <img className="logo" alt="fontannyplywajace.pl" src="/zzm-logo2.png" />
                </a>
            </div>
        )
    }
}