import React from 'react';
import { io, Socket } from "socket.io-client";
import './App.scss';
import { SplashScreen } from './views/SplashScreen/SplashScreen';
import Localization from './views/Localization/Localization';
import Layout from './views/Layout';
import Presentation from './views/Presentation/Presentation';
import Player from './views/Player/Player';
import DeviceOffline from './views/DeviceOffline/DeviceOffline';
import { PilotService } from "./service/pilot.service";
import PresentationInProgress from './views/PresentationInProgress/PresentationInProgress';

enum SWITCH { A, B }
enum SWTICH_STATE { OFF, ON }

export interface IAppState {
  audio: boolean,
  welcome: boolean,
  localization: boolean,
  presentationInProgress: boolean
  play: boolean
  pilot: boolean
  online: boolean,
  playlist: number[],
  initialTime: number,
  join: boolean,
  song: {
    time: number,
    total: number,
    index: number
  }
}

class App extends React.Component<any, IAppState> {
  audioFiles = [
    "/music/1.mp3",
    "/music/2.mp3",
    "/music/3.mp3",
    "/music/4.mpeg",
  ];
  socket: Socket = io();
  audio = React.createRef<HTMLAudioElement>();
  pilot: PilotService = new PilotService(this.socket);

  constructor(props: any) {
    super(props);

    this.state = { ...this.getInitialState(), audio: false };
  }

  componentWillUnmount() {

  }

  getInitialState() {
    return {
      welcome: false,
      localization: false,
      presentationInProgress: false,
      play: false,
      pilot: false,
      online: false,
      join: false,
      playlist: [0, 0, 0, 0],
      initialTime: 0,
      song: {
        time: 0,
        total: 0,
        index: -1
      }
    }
  }


  onLocalizationOk = () => {
    this.socket.emit("okLocation");
    this.setState({ localization: true });

  }

  componentDidMount() {
    for (var i in this.audioFiles) {
      this.preloadAudio(this.audioFiles[i]);
    }

    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === 'hidden') {
        this.socket.disconnect();
      }
    });

    this.socket.on("connect", () => {
      this.socket.emit("client");
    });

    this.socket.on("disconnect", () => {
      this.setState(this.getInitialState());
    });

    this.socket.on("relay", (state: number) => {
      this.setState({ online: state == 1 });
      if (state == 0) {
        this.socket.disconnect();
      }
    });

    this.socket.on("playlist", (playlist: any) => {
      this.setState({ playlist: playlist });
    });

    this.socket.on("pilot", () => {
      this.setState({ pilot: true });
    });

    this.socket.on("play", () => {
      this.setState({ play: true })
    });

    this.socket.on("time", (data: { time: number, total: number, index: number }) => {
      this.setState({ song: data });
    });
  }

  preloadAudio = (url: string) => {
    var audio = new Audio();
    audio.oncanplaythrough = this.loadedAudio;
    audio.src = url;
  }

  loaded: number = 0;
  loadedAudio = () => {
    this.loaded++;
    if (this.loaded == this.audioFiles.length) {
      this.setState({ audio: true })
    }
  }

  onSelectPresentation = (presentation: number) => {
    this.pilot.play(presentation);
    this.setState({ play: true, song: { index: presentation, time: 0, total: this.state.playlist[presentation] } });
  }

  render() {
    if (!this.state.online) {
      return <Layout><DeviceOffline /></Layout>
    }

    if (!this.state.welcome) {
      return <Layout><SplashScreen loading={!this.state.audio} onOk={() => this.setState({ welcome: true })} /></Layout>
    }

    if (!this.state.localization) {
      return <Layout><Localization localizationOk={() => this.onLocalizationOk()} /></Layout>
    }

    if (!this.state.pilot && !this.state.join) {
      return <Layout><PresentationInProgress onAcceptJoin={() => this.setState({ join: true })} /></Layout>
    }

    return <Layout><Presentation play={this.state.play} files={this.audioFiles} song={this.state.song} initialTime={this.state.initialTime} playlist={this.state.playlist} pilot={this.state.pilot} onSelectedPresentation={(presentation: any) => this.onSelectPresentation(presentation)} /></Layout>
  }
}

export default App;
