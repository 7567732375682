import React from "react";
import { TopNavbar } from "../../components/TopNavbar/TopNavbar";
import NoSleepJs from "nosleep.js";

import "./SplashScreen.scss"

interface ISplashScreenProps {
    onOk: () => void,
    loading: boolean
}

export class SplashScreen extends React.Component<ISplashScreenProps, any> {

    audioFiles = [
        "/music/1.mp3",
        "/music/2.mp3",
        "/music/3.mp3",
        "/music/4.mpeg",
    ];

    _noSleep;

    constructor(props: ISplashScreenProps) {
        super(props);
        this._noSleep = new NoSleepJs();

        this.state = {
            loading: false
        }
    }

    onClickStart = (e: any) => {
        this._noSleep.enable();
        for (var i in this.audioFiles) {
            this.preloadAudio(this.audioFiles[i]);
        }



        this.props.onOk()
    }

    preloadAudio = (url: string) => {
        var audio = new Audio();
        audio.oncanplaythrough = this.loadedAudio;
        audio.src = url;
    }

    loaded: number = 0;
    loadedAudio = () => {
        this.loaded++;
        if (this.loaded == this.audioFiles.length) {
            this.props.onOk()
            // this.setState({ audio: true })
        }
    }

    render() {
        return (<div className="splash-screen container">
            <div className="welcome-message mt-4">WITAJ!</div>
            {this.state.loading && <>
                <h3 className="text-center mt-4">Ładowanie aplikacji...</h3>
            </>}
            {!this.state.loading && <>
                <h3 className="text-center mt-4">Znajdujesz się w aplikacji sterującej fontanną. Dzięki niej będziesz mógł uruchomić pokaz.</h3>
                <button className="btn btn-primary mt-4 btn-start" onClick={(e) => this.onClickStart(e)}>Zaczynamy</button>
            </>}

        </div>);
    }
}